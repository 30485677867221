.hidden {
  visibility: hidden;
}
nav {
  margin: 0;
}
.pagination {
  width: 85vw;
  display: flex;
  justify-content: center; 
  align-items: center;
  list-style: none;
  height: 40px;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination button:hover {
  background-color: #CED7C1;
  transition: all 0.3s;
}

.pagination .active button {
  background-color: #5d6d46;
  color: white;
}

.pagination button {
  background-color: #fff;
  border: 1px solid #CED7C1;
  color: #333;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s;
}

/* Responsive */
@media screen and (max-width: 750px){
  .pagination {
    flex-wrap: wrap;
  }
  .button {
    display: none; 
  }
}