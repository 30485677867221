.profil-flex {
    display: flex;
}

/* Responsive */
@media screen and (max-width: 1250px){

}
@media screen and (max-width: 1000px){

}
@media screen and (max-width: 750px){
    .profil-flex {
        flex-direction: column;
        height: 100%;
    }
}
@media screen and (max-width: 500px){

}