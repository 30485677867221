.menu-all {
    display: flex;
    position: relative;
}
.menu-ctn {
    margin: 0;
    height: 95vh;
    min-width: 12.5vw;
    background-color: #9daf83;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    transition: transform 0.3s ease-in-out;
    gap: 20px;
}
.closed {
    transform: translateX(-100%); 
}
.visible {
    transform: translateX(0); 
}
.toggle-menu-button {
    width: 2vw;
    border: 1px solid #5d6d46 ;
    background: #9daf83;
    transition: transform 0.3s ease-in-out;
}
.toggle-menu-button:hover {
    background: #5d6d46;
    transition: all 0.3s ease-in-out;
}
.menu-list {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.menu-list li{
    width: 100%;
    display: flex;
    text-align: center;
    color: black;
}
.menu-list li:hover a, .menu-list li:hover{
    background: #5d6d46;
    color: white;
}
.menu-list li a:active, .menu-list li:active{
    background: #5d6d46;
    color: white;
}
.menu-list i{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin: 0 20px;
}
.menu-list a{
    width: 100%;
    text-align: left;
}
.menu-list li a{
    display: flex;
    color: black;
    padding: 20px 20px 20px 0;
}
li.li-btn{
    display: flex;
    justify-content: flex-end;
    padding: 0;
}
li.li-btn button{
    font-family: 'Montserrat';
    width: 100%;
    padding: 20px 0;
    background: none;
    border: none;
    color: black;
}
li.li-btn button:hover{
    background: #5d6d46;
    color: white;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 105vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  
.modal-content,
.modal-content-add {
    background: white;
    color: black;
    padding: 40px;
    border-radius: 20px;
}
.add-btn.mob {
    display: none;
}
/* Responsive */
@media screen and (max-width: 1500px){
    .menu-ctn {
        min-height: 100vh;
        height: 100%;
    }
    .toggle-menu-button {
        display: none;
    }
}
@media screen and (max-width: 1250px){
    .menu-ctn {
        width: 20vw;
    }
}
@media screen and (max-width: 750px){
    .menu-all {
        width: 100%;
        overflow-x: hidden;
        flex-direction: column;
    }
    .menu-ctn {
        min-width: 100%;
        min-height: 0;
        height: 100%;
        padding: 10px 0;
        justify-content: center;
        gap: 1vh;
    }
    .menu-list {
        flex-direction: row;
    }
    .menu-list li a{
        padding: 5px 0;
        justify-content: center;
    }
    .menu-list i{
        margin: 0 10px;
    }
    li.li-btn.desk{
        display: none;
    }
    .add-btn.mob {
        display: block;
        margin: 10px auto;
        border: 1px solid #F5F1E3;
        border-radius: 5px;
        background: #CED7C1;
        padding: 10px;
        transition: all 0.3s;
    }
}
@media screen and (max-width: 500px){
    .menu-list i{
        margin: 0 5px;
    }
}