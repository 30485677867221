.login-ctn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    height: 100vh;
    background: url('../../assets/login.jpg') no-repeat;
    background-position: 50%;
}
.login-welcome {
    border: 5px solid black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: black;
    padding: 10px;
}
.login-welcome h1, h2 {
    margin: 25px 10px;
}
.login-logs {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border: 5px solid black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: black;
    padding: 10px;
    gap: 10px;
}
.form-btn {
    margin-top: 10px;
    background: none;
    border: none;
    color: white;
    text-decoration: none;
    cursor: pointer;
}
.login-welcome,
.login-logs {
    width: 20%;
}
.error {
    color: red;
}

/* Responsive */
@media screen and (max-width: 1000px){
    .login-welcome,
    .login-logs {
        width: 30%;
    }
}
@media screen and (max-width: 750px){
    .login-welcome,
    .login-logs {
        width: 40%;
    }
}
@media screen and (max-width: 500px){
    .login-welcome,
    .login-logs {
        width: 50%;
    }
}