.items-ctn {
    width: 85vw;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto ;
    gap: 20px;
}
.items-flex {
    display: flex;
}
.all-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 95%;
    padding: 0;
    margin: auto;
}
.all-items li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #CED7C1;
    border-radius: 25px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    max-height: 208px;
}
.all-items li:hover {
    transition: all 0.3s;
    background-color: #9daf83;
    scale: 1.015;
}
.all-items li img{  
    height: 100px;
    max-width: 95%;
    padding: 10px;
    border-radius: 20px;
    object-fit: contain;
}
.items-quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 10px 0;
    gap: 20px;
}
.items-quantity button{
    border: 1px solid #F5F1E3;
    border-radius: 5px;
    background: #CED7C1;
    padding: 5px 10px;
    transition: all 0.3s;
}
.items-quantity button:hover{
    background: #F5F1E3;
    padding: 5px 10px;
    transition: all 0.3s;
}
.all-items li h2,h3,h4,p {  
    text-align: center;
    margin: 0;
}
.all-items li h2{  
    margin: 5px;
}
.all-items h3 {
    font-size: 17px;
}
.item-etat {
    position: absolute;
    top: 10px;
    left: 10px;
}
.item-del {
    border: none;
    background: none;
}
.item-del i {
    color: black;
}
.item-del i:hover {
    color: red;
}
.article-error {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

/* Responsive */
@media screen and (max-width: 1500px){
    .all-items {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
    }
}
@media screen and (max-width: 1250px){
    .all-items {
        grid-template-columns: 1fr 1fr 1fr;
        width: 75vw;
    }
    .items-ctn {
        width: 80vw;
    }
}
@media screen and (max-width: 1000px){
    .all-items {
        grid-template-columns: 1fr 1fr;
        margin: 0;
    }
    .all-items li {  
        width: 90%;
        margin: auto;
    }
}
@media screen and (max-width: 750px){
    .items-ctn {
        width: 100vw;
        height: 100%;
        margin: auto;
        position: relative;
        left: 0;
        gap: 10px;
    }
    .all-items {
        width: 90vw;
        margin: 0 auto 0;
        justify-content: center;
        align-items: center;
    }
    .all-items li h3 {  
        font-size: 15px;
    }
    .all-items li h4 {  
        font-size: 14px;
    }
    .all-items li p {  
        font-size: 12px;
    }
}
@media screen and (max-width: 500px){
    .items-ctn {
        height: 85vh;
        margin: 0;
    }
    .all-items li h3 {  
        font-size: 14px;
        height: 36px;
        display: flex;
        align-items: center;
    }
    .all-items li h4 {  
        font-size: 13px;
    }
    .all-items {
        margin: 0;
    }
    .all-items li {
        width: 95%;
        overflow: hidden;
        border-radius: 20px;
    }
    .all-items li img {
        min-height: 100px;
        width: 95%;
        border-radius: 10px;
        object-fit: contain;
        padding-bottom: 0;
    }
    .items-quantity  {
        gap: 5px;
    }
    .item-etat {
        padding: 1px 3px;
        top: 8px;
        left: 8px;
    }
}