.articles-ctn {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: auto;
}

/* Responsive */
@media screen and (max-width: 1500px){

}
@media screen and (max-width: 1250px){

}
@media screen and (max-width: 1000px){

}
@media screen and (max-width: 750px){
    .articles-ctn {
        justify-content: center;
        margin: 0;
        padding: 0;
    }
}