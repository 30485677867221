.home-first {
    display: flex;
    height: 95vh;
    width: 100%;
}
.home-both {
    display: flex;
    flex-direction: column;
}
  
/* Responsive */
@media screen and (max-width: 750px){
    .home-first {
        flex-direction: column;
        height: 100%;
    }
}