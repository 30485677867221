.profil-ctn{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
}
.update-ctn{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
}
.left-part{
    width: 40%;
    max-height: 75vh;
    display: flex;
    gap: 20px;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    background: #ced7c1;
    transition: all 0.3s;
}
.left-part:hover{
    background: #9daf83;
    transition: all 0.3s;
}
.left-part img {
    width: 50%;
    max-height: 75%;
    object-fit: cover;
    border-radius: 75px;
}
.right-part{
    width: 40%;
    max-height: 75vh;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    background: #ced7c1;
    transition: all 0.3s;
}
.right-part:hover{
    background: #9daf83;
    transition: all 0.3s;
}
.right-part h4{
    margin: 25px 0 0;
}
.right-part p{
    margin: 0;
}
.right-part button {
    margin-top: 10px;
    cursor: pointer;
    border: 2px solid #EEEEEE;
    border-radius: 20px;
    padding: 5px 10px;
    background-color: #EEEEEE;
    transition: all 0.3s;
    width: 25%;
}
.right-part button:hover{
    background-color: #2D373C;
    color: white;
    transform: scale(1.1);
    transition: all 0.3s;
}
.right-part img {
    width: 50%;
}

/* Responsive */
@media screen and (max-width: 1250px){

}
@media screen and (max-width: 1000px){
    .right-part button {
        width: 50%;
    }
    .update-ctn{
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
    }
    .left-part,
    .right-part {
        width: 60vw;
        margin: auto;
    }
}
@media screen and (max-width: 750px){
    .left-part,
    .right-part {
        width: 85vw;
        margin: auto;
    }
    .left-part img {
        width: 50%;
        max-height: 50%;
    }
    .left-part label {
        width: 50%;
    }
}