.modal-content-add {
    padding-top: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}
.article-add-ctn button {
    background: #CED7C1;
    border: 1px solid #CED7C1;
    border-radius: 20px;
    color: black;
    font-family: 'Montserrat';
    transition: all 0.3s;
    padding: 5px 10px;
    cursor: pointer;
}
.article-add-ctn button:hover {
    background-color: #5d6d46;
    border: 1px solid white;
    scale: 1.1;
    transition: all 0.3s;
    color: white;
}
.modal-close{
    position: absolute;
    right: 0;
    top: 0;
    background: black;
    border: none;
    color: white;
}   
.modal-close button:hover{
    background: red;
}
#article-add-form {
    display: flex;
    flex-direction: column;
}
#article-add-form label{
    text-align: center;
}

/* Responsive */
@media screen and (max-width: 1500px){

}
@media screen and (max-width: 1250px){

}
@media screen and (max-width: 1000px){

}
@media screen and (max-width: 750px){

}