h2 {
    margin: 0 0 10px;
    text-align: center;
}
.stats-ctn {
    display: flex;
    height: 40%;
    width: 85vw;
    position: relative;
    border-bottom: 1px solid black;
    padding: 10px 10px 20px 10px ;
}
.stats-total table,
.stats-etats table {
    width: 250px;
}
table, th, td {
    border: 1px solid black;
    font-size: 14px;
    padding: 2px 5px;
    text-align: center;
    border-collapse: collapse;
}
.tr-etats,
.tr-fournisseur{
    border-bottom: 1px solid black;
}
.collapsible .content {
    padding: 6px;
    background-color: #eeeeee;
}
.collapsible .header {
    background-color: #dddddd;
    padding: 6px;
    cursor: pointer;
}
.collapse-content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
}
.collapse-content.expanded {
    max-height: 100px; 
}
.stats-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    gap: 10px;
    height: 100%;
}
.stats-fournisseurs,
.stats-etats,
.stats-total {
    display: flex;
    flex-direction: column;
    
    margin: 0 auto;
    height: 100%;
}
.stats-etats table,
.stats-total table,
.stats-fournisseurs table{
    height: 100%;
}
.table-container {
    display: flex;
    gap: 20px;
    height: 100%;
}
.ul-fournisseurs {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    justify-content: center;
    padding: 0;
    margin: auto;
}
.li-fournisseur{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 11vh;
    border: 1px solid black;
    border-radius: 20px;
}
.ul-etats {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    justify-content: center;
    padding: 0;
    margin: auto;
}
.li-etats{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 11vh;
    border: 1px solid black;
    border-radius: 20px;
}
.td-fournisseur,
.td-case {
    width: 8vh;
    font-weight: bold;
}
.stats-fournisseurs.mob {
    display: none;
}

/* Responsive */
@media screen and (max-width: 1500px){
    .stats-ctn {
        height: 100%;
        padding: 10px 0;
        flex-direction: column;
        width: 90vw;
    }
    .stats-fournisseurs,
    .stats-etats,
    .stats-container {
        margin: 0 auto;
    }
    .table-container,
    .stats-container {
        width: 65vw;
        margin: auto;
    }
    .stats-container {
        margin-bottom: 10px;
    }
    .table-container table,
    .stats-total table,
    .stats-etats table {
        width: 100%;
        margin: 0 auto;  
    }
    .stats-etats,
    .stats-total {
        width: 50%;
    }
}
@media screen and (max-width: 1250px){
    .stats-ctn {
        width: 80vw;
    }
}
@media screen and (max-width: 1000px){
    .stats-ctn {
        width: 80vw;
    }
    .desk {
        display: none;
    }
    .stats-fournisseurs.mob {
        display: block;
        margin: 10px auto 30px;
        width: 70vw;
    }
    h2 {
        font-size: 18px;
    }
    .stats-container {
        gap: 10px;
        height: 100%;
        width: 70vw;
        margin: 0 auto;
        justify-content: space-evenly;
    }
    .table-container {
        width: 100%;
        height: 100%;
    }
    .table-container table,
    .stats-total table,
    .stats-etats table {
        width: 100%;
        height: 100%; 
    }
    .stats-etats,
    .stats-total {
        width: 100%;
        height: 100%; 
    }
}
@media screen and (max-width: 750px){
    .stats-ctn {
        width: 100%;
        margin: auto;
    }
    .stats-container {
        width: 80%;
    }
    .stats-fournisseurs.mob {
        width: 80%;
        margin-bottom: 10px;
    }

    .stats-etats,
    .stats-total {
        height: 160px; 
    }
}
@media screen and (max-width: 500px){
    .stats-container {
        flex-direction: column;
        margin-bottom: 20px;
    }
    .stats-total table,
    .stats-etats table {
        width: 100%;
    }
}