.modal-content {
    padding-top: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 40%;
}
.modal-close{
    position: absolute;
    right: 0;
    top: 0;
    background: black;
    border: none;
    color: white;
    padding: 10px 0;
}
.modal-close:hover{
    background: red;
}
.modal-ctn {
    display: flex;
}
.modal-left,
.modal-right {
    width: 100%;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
img.modal-left-img {
    max-width: 300px;
    max-height: 300px;
    object-fit: cover;
}
.upload-image {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 25px;
}
.upload-image label {
    cursor: pointer;
    font-size: 14px;
    font-family: 'Montserrat';
}
.upload-image-btn{
    display: none;
}
.modal-btn {
    background: #CED7C1;
    border: 1px solid #CED7C1;
    border-radius: 20px;
    color: black;
    font-family: 'Montserrat';
    transition: all 0.3s;
    padding: 5px 10px;
    cursor: pointer;
}
.modal-btn:hover {
    background-color: #5d6d46;
    border: 1px solid white;
    scale: 1.1;
    transition: all 0.3s;
    color: white;
}
.modal-infos h4{
    margin-bottom: 5px;
}
.error-message {
    color: red;
    font-weight: bold;
}

/* Responsive */
@media screen and (max-width: 1250px){
    .modal-ctn {
        gap: 20px;
    }
    .modal-content {
        width: 60%;
    }
    .modal-left,
    .modal-right {
        margin: 0;
    }
}
@media screen and (max-width: 1000px){
    .modal-ctn {
        flex-direction: column;
        gap: 20px;
    }
    img.modal-left-img {
        max-width: 200px;
        max-height: 200px;
        object-fit: cover;
    }
}
@media screen and (max-width: 750px){
    .modal-ctn {
        flex-direction: column;
        gap: 20px;
    }
    .modal-content {
        padding: 15px;
    }
    .modal-left,
    .modal-right {
        margin: 0;
    }
}
@media screen and (max-width: 500px){

}