.contact-ctn {
    display: flex;
}
.display-none {
    display: none;
}
.contact-flex {
    height: 90vh;
    width: 85vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 25px;
}
.all-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: auto;
    gap: 30px;
}
.all-contact li img{  
    border-radius: 20px;
}
.all-contact li,h3,p {  
    text-align: center;
    margin: 0;
}
.contact-title {
    margin: 20px;
}
.contacts-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
}
.contact-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}   
.contact-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: #ced7c1;
    border-radius: 25px;
    width: 20vw;
    height: 100%;
    margin: 0 auto;
    padding: 10px 5px;
    gap: 5px;
}
.contact-item a {
    color: black;
    border: 1px solid #5d6d46;
    padding: 2px 5px;
    border-radius: 5px;
    font-style: italic;
    transition: all 0.3s;
}
.contact-item a:hover {
    scale: 1.05;
    color: white;
    background-color: #5d6d46;
    transition: all 0.3s;
}

/* Responsive */
@media screen and (max-width: 1250px){
    .contact-item {
        width: 260px;
    }
    .contact-item h3{
        font-size: 16px;
    }
    .contact-item p{
        font-size: 12px;
    }
    .contact-item a{
        font-size: 12px;
    }
    .contacts-row {
        gap: 10px;
    }
}
@media screen and (max-width: 1000px){
    .contact-flex {
        height: 100%;
        margin-bottom: 30px;
    }
    .contacts-row {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }
}
@media screen and (max-width: 750px){
    .contact-ctn {
        flex-direction: column;
        height: 100%;
    }
    .contact-flex {
        width: 100%;
    }
}
@media screen and (max-width: 600px){
    .contacts-row {
        grid-template-columns: 1fr;
        gap: 30px;
    }
    .contact-item {
        width: 80vw;
    }
}