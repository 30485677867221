.header {
    height: 5vh;
    width: 100vw;
    background-color: #b6c3a2;
    display: flex;
    justify-content: space-between;
}
.header-menu {
    display: flex;
    flex-direction: column;
    min-width: 15vw;
}
.header-btn {
    height: 5vh;
    min-width: 177px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #b6c3a2;
    border: none;
}
.header-logo{
    height: 5vh;
}
.header-tools {
    display: flex;
    align-items: center;
    gap: 25px;
}
.header-icons {
    color: #6F6F6F; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    gap: 25px;
}
.btn-logout {
    padding: 0;
    color: #6F6F6F; 
    background: none;
    border: none;
}
.header-pic  {
    cursor: pointer;
    height: 5vh;
}
.menu-close {
    background-color: #202A2F;
    color: white;
    border: none;
    width: 100%;
    cursor: pointer;
    text-align: center;
    padding: 10px 0;
}

/* Responsive */
@media screen and (max-width: 750px){
    .header-menu {
        display: none;
    }
    .header-welcome h5 {
        font-size: 12px;
    }
    .header-icons i {
        margin: 0;
    }
    .header-icons {
        width: 25%;
        gap: 15px;
        justify-content: space-between;
        margin-right: 15px;
    }
    .header-welcome {
        width: 75%;
        padding: 0 10px;
    }
    .header-tools {
        width: 100%;
        justify-content: space-around;
        gap: 15px;
    }
}